import React from "react";
import "./card.css";
import { useNavigate } from "react-router-dom";

function Card({ data }) {
  const navigate = useNavigate();

  const handleCardClick = () => {
    navigate(`/view3d/${data.uuid}`);
  };

  return (
    <section className="card h-auto" onClick={handleCardClick}>
      <img src={data.img} alt={data.uuid} className="cardImg" />
      <br />
      <div className="cardDetails">
        <h4 className="cardTitle">{data.title}</h4>
        <p className="cardCategory">
          <b>Category: </b>
          {data.category}
        </p>
      </div>
    </section>
  );
}

export default Card;
