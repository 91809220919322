import React, { useState } from "react";
import Nav from "./Navigation/Nav.js";
import Products from "./Products/Products.js";
import Card from "./components/Card.js";
import "./index.css";
import data from "../src/db/data.js";

function App() {
  const [query, setQuery] = useState("");

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  const result = data.map((data) => <Card key={data.uuid} data={data} />);

  return (
    <>
      <Nav query={query} handleInputChange={handleInputChange} />
      <Products result={result} />
    </>
  );
}

export default App;
