import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "./App.js";
import View3d from "./view3d/View3d.js";
import data from "../src/db/data.js";


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Routes>
      <Route exact path="/" element={<App />} />
      <Route exact path="/view3d/:uuid" element={<View3d data={data} />} />
    </Routes>
  </BrowserRouter>
);
