import React from "react";
import "./nav.css";



function Nav({ handleInputChange, query }) {
  return (
    <nav className="fixed w-screen h-auto top-0 z-50 bg-white border-b-4">
      <div className="navContainer ">
      <img src="https://d2uimaqek2eby3.cloudfront.net/testdashsquare/thumbnails/logo.png" alt="logo" className="logo w-72" />
        {/* <input
          type="text" 
          onChange={handleInputChange}
          value={query}
          placeholder="Search"
          className="searchInput"
        /> */}
      </div>
 
    </nav>
  );
}

export default Nav;
