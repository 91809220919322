
const data = [
  {
    img: "https://d2uimaqek2eby3.cloudfront.net/zeesh/blue.png",
    title: "ZLVII BLUE",
    color: "Blue",
    category: "Footwear",
    glbFile: "https://d2uimaqek2eby3.cloudfront.net/zeesh/blue.glb",
    usdzFile: "https://d2uimaqek2eby3.cloudfront.net/zeesh/blue.usdz",
    uuid: "shoe1"
   },
   {
    img: "https://d2uimaqek2eby3.cloudfront.net/zeesh/airforce_shoe_mini.png",
    title: "NIKE",
    color: "Grey",
    category: "Footwear",
    glbFile: "https://d2uimaqek2eby3.cloudfront.net/zeesh/airforce_shoe_mini.glb",
    usdzFile: "https://d2uimaqek2eby3.cloudfront.net/zeesh/airforce_shoe_mini.usdz",
    uuid: "shoe2"
   },
   
  ];
export default data; 
