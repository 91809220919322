import React from "react";
import "./Products.css";

function Products({ result }) {
  return (
    <>
      <section className="cardContainer mt-40">{result}</section>
    </>
  );
}

export default Products;
