import React, { useState} from "react";
import { useParams } from 'react-router-dom';
import outdoor_hdr from "../assets/meadow_2k.hdr";
import indoor_hdr from "../assets/school_hall_2k.hdr";
import studio_hdr from "../assets/studio.hdr";
import "./View3d.css"

function View3d({ data }) {
 const { uuid } = useParams();
 const [selectedLighting, setSelectedLighting] = useState(studio_hdr); // Default lighting option
 let exposure;

 const modelData = data.filter(item=>item.uuid === uuid);
 console.log(modelData);

 const handleLightingChange = (event) => {
  setSelectedLighting(event.target.value);
};
if(modelData[0].uuid ==='shoe2'){
   exposure = '2'
}else{
  exposure = '0.5'
}
 
  return (
    <div className="view3d flex justify-around">
      <div className="modelViewer">
        <model-viewer
          id="static-model"
          alt="3D model"
          src={modelData[0].glbFile}
          ios-src={modelData[0].usdzFile}
          // ar-scale="fixed"
          shadow-intensity="0"
          exposure = {exposure}
          environment-image={selectedLighting}
          disable-tap
          ar
          camera-controls
          // min-camera-orbit="auto 0deg auto" 
          // max-camera-orbit="auto 79deg auto"
          touch-action="pan-y"
        ></model-viewer>
      </div>
      {/* <div className="lighting">
      <label>
          Studio Lighting
          <input
            type="radio"
            value={studio_hdr}
            checked={selectedLighting === studio_hdr}
            onChange={handleLightingChange}
            className="ml-5"
          />
        </label>
        <label>
          Indoor Lighting
          <input
            type="radio"
            value={indoor_hdr}
            checked={selectedLighting === indoor_hdr}
            onChange={handleLightingChange}
            className="ml-5"
          />
        </label>
        <label className="text-sm" >
          Outdoor Lighting
          <input
            type="radio"
            value={outdoor_hdr}
            checked={selectedLighting === outdoor_hdr}
            onChange={handleLightingChange}
            className="ml-5"
          />
        </label>
        </div> */}
    </div>
  );
}

export default View3d;
